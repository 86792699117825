import React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Grid, Margin, Picture, LinkMask, } from '@components/atoms'
import { TagText, ShopH4, H3 } from '@components/atoms/BlockRenderer'
import { GridItem, GridItemScrollStrip, Hero, GridRule } from '@components/molecules'
import { TransitionMask } from '@components/organisms'
import { PageProps } from '@types'
import { palette } from '@theme'
import { getCdnPath } from '@utils'
import { Helmet } from 'react-helmet'


const ShopPage = ({data: { 
  sanityPage: {
    heroType, 
    heroHeight,
    heroFX,
    heroImageConditional, 
    heroVideoConditional,
    heroParallaxConditional,
    gridItems,
    gridSnap,
    inverted,
    shareImage,
    description,
    title,
  },
  shopifyCollection
}}: PageProps) => {
  if(!shopifyCollection) return <></>

  const parsedTitle = `TWA: ${title}`
  const meta = [
    { property: "og:title", content: parsedTitle },
    { name: "twitter:title", content: parsedTitle },
    { name: "description", content: description },
    { name: "twitter:description", content: description },
    { property: "og:description", content: description },
  ]
  if(shareImage) {
    meta.push({ property: "og:image", content:  getCdnPath(`${shareImage.asset.url}?w=640&fm=jpg`) })
    meta.push({ name: "twitter:image", content:  getCdnPath(`${shareImage.asset.url}?w=640&fm=jpg`) })
  }

  const { products } = shopifyCollection
    
  return (
    <>
      <Helmet {...{meta}} title={parsedTitle} >
        <title>{parsedTitle}</title>
      </Helmet>

      <Hero {...{ heroType, heroHeight, heroImageConditional, heroVideoConditional, heroParallaxConditional, heroFX }} />
      
      <Margin>
        <Grid {...{ gridSnap }} >

          <GridRule ruleType="gap" gapHeight={10} _key="whatever" />

          <Introduction>
            <H3>A place where we put our creative skills to work for a good cause. All merchandise is designed by our studio with 100% of proceeds donated to a charitable organization selected each quarter.</H3>
          </Introduction>
          
          <GridRule ruleType="gap" gapHeight={3} _key="whatever" />
          
          <AboutBlock columnStart={4} rightLine >
            <img src="/images/shop-glyph-1.svg" />
            <ShopH4>All for Charity</ShopH4>
            <p>All of our profits go to charities gobally.</p>
          </AboutBlock>
          <AboutBlock columnStart={7} rightLine >
            <img src="/images/shop-glyph-2.svg" />
            <ShopH4>Free Shipping</ShopH4>
            <p>We’ll ship for free anywhere in the U.S.</p>
          </AboutBlock>
          <AboutBlock columnStart={10}>
            <img src="/images/shop-glyph-3.svg" />
            <ShopH4>Limited Edition</ShopH4>
            <p>Custom designed by our team.</p>
          </AboutBlock>
          
          <GridRule ruleType="gap" gapHeight={10} _key="whatever" />
          
          <GridItemScrollStrip stripStyle="scroll" >
            {products.map(product => (
              <Product key={product.handle} >
                <LinkMask to={product.handle} />
                <Picture src={product.featuredImage.originalSrc} />
                <ProductInfo>
                  <TagText>{product.title}</TagText>
                  <Price>${product.variants[0].price.toString().replace('.00', '')}</Price>
                </ProductInfo>
              </Product>
            ))}
          </GridItemScrollStrip>
        
          <GridRule ruleType="gap" gapHeight={10} _key="whatever" />
          
          {gridItems.filter(p => !!p).map((item) => (
            <GridItem 
              key={item._key} 
              {...item} 
            />
          ))}
          
          
        </Grid>
      </Margin>
            
      <TransitionMask {...{ inverted }} />
    </>
  )
}

const Product = styled.div`
  position: relative;
  grid-column-end: span 5;
  @media only screen and (min-width: 744px) {
    grid-column-end: span 3;
  }
`

const ProductInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.5em;
`

const Introduction = styled.div`
  grid-column-start: 1;
  grid-column-end: span 6;
  @media only screen and (min-width: 744px) {
    grid-column-start: 4;
  }
`

const Price = styled(props => <TagText {...props} />)`
  color: ${palette.gray};
`


const AboutBlock = styled.div<{ columnStart: number, rightLine?: boolean }>`
  grid-column-end: span 6;
  text-align: center;
  padding-bottom: 2em;
  padding-right: 1em;
  img {
    max-width: 100px;
    max-height: 120px;
    margin-bottom: 1em;
  }
  @media only screen and (min-width: 744px) {
    text-align: left;
    grid-column-start: ${props => props.columnStart};
    grid-column-end: span 3;
    ${props => props.rightLine && `
      border-right: 1px solid ${palette.teak};
    `}
    padding-bottom: 0;
  }
  align-self: flex-end;
`


export const query = graphql`
  query ShopPageQuery {
    sanityPage(slug: {current: {eq: "shop"}}) {
      ...pageFields
    }
    shopifyCollection(handle: {eq: "frontpage"}) {
      products {
        title
        handle
        featuredImage {
          originalSrc
        }
        variants {
          price
        }
      }
    }
  }
`

export default ShopPage
